import React from 'react';
import SbEditable from 'storyblok-react';
import templating from '../templating';
import { STORYBLOK_CONFIG } from '../../common';

const loadStoryblokBridge = function(cb) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${process.env.STORYBLOK_TOKEN}`;
  script.onload = cb;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const getParam = function(val) {
  let result = '';
  let tmp = [];

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function(item) {
      tmp = item.split('=');
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1]);
      }
    });

  return result;
};

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      story: null,
    };
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents();
    });
  }

  loadStory(payload) {
    window.storyblok.get(
      {
        slug: getParam('path'),
        version: 'draft',
        resolve_relations: STORYBLOK_CONFIG.resolveRelations, // https://github.com/storyblok/gatsby-storyblok-boilerplate/commit/8b1ebef4033a0ddd27870b32f8e6f626c9edf071
      },
      (data) => {
        this.setState({ story: data.story });
      },
    );
  }

  initStoryblokEvents() {
    this.loadStory({ storyId: getParam('path') });

    const sb = window.storyblok;

    sb.on(['change', 'published'], (payload) => {
      this.loadStory(payload);
    });

    sb.on('input', (payload) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        sb.addComments(payload.story.content, payload.story.id);
        // https://www.storyblok.com/cl/resolve-relations-with-storyblok-bridge
        sb.resolveRelations(
          payload.story,
          STORYBLOK_CONFIG.resolveRelations,
          () => {
            // data.story.content has now the resolved relations
            // this.content = data.story.content
            this.setState({ story: payload.story });
          },
        );
      }
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  }

  render() {
    if (this.state.story == null) {
      return <div />;
    }

    const { content } = this.state.story;
    const contentType = this.state.story.content.component;

    return (
      <SbEditable content={content}>
        <div>{templating(contentType, content, {})}</div>
      </SbEditable>
    );
  }
}

export default StoryblokEntry;
